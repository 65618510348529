<template>
  <div>
    <v-dialog v-model="groupdeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete Group</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Delete {{ groupinfo.group_name }}?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              :loading="loading"
              @click="delete_freeforms()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
      <script>
import { API, graphqlOperation } from "aws-amplify";
import { create_delete_groups } from "@/graphql/mutations.js";
export default {
  components: {},
  props: {
    groupdeletion: Boolean,
    groupinfo: Object,
  },
  data: () => ({
    loading: false,
  }),
  watch: {
    groupdeletion: {
      handler() {
        {
          //   console.log(this.groupinfo);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    async delete_freeforms() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(create_delete_groups, {
            input: {
              user_id: this.$store.getters.get_org_user_details[0].user_id,
              action_type: "DELETE",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              group_name: this.AdminGroup,
              group_description: this.groupdescrip,
              group_admin: this.member_name,
              group_id:this.groupinfo.group_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.create_delete_groups);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>