var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"550px"},model:{value:(_vm.membersgroup),callback:function ($$v) {_vm.membersgroup=$$v},expression:"membersgroup"}},[_c('v-toolbar',{staticClass:"gradient-bg elevation-0",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Add Members to Group : ( "+_vm._s(_vm.groupinfo.group_name)+" )")]),_c('v-spacer'),_c('v-icon',{staticClass:"white--text mr-n3",on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v("mdi-close")])],1),_c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-text',[_c('v-toolbar',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-2",staticStyle:{"max-width":"200px"},attrs:{"flat":"","label":"Search","dense":"","append-icon":"mdi-magnify","placeholder":"Search by Member Name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.sortfunction()}},model:{value:(_vm.sorttabel),callback:function ($$v) {_vm.sorttabel=$$v},expression:"sorttabel"}},[_c('v-radio',{attrs:{"label":"ALL","value":"All"}}),_c('v-radio',{attrs:{"label":"Same Group","value":"current_group"}})],1)],1),_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"height":_vm.height,"dense":"","headers":_vm.MembersDataActive,"items":_vm.newMemberitems,"items-per-page":25,"fixed-header":_vm.fixed,"loading":_vm.isLoadingMember,"loading-text":"Loading... Please wait","search":_vm.search,"footer-props":{
            'items-per-page-options': [10, 15, 20, 25],
          },"sort-by":['user_name']},scopedSlots:_vm._u([{key:"item.join_status",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.join_status === 'NO_GROUP'
                  ? false
                  : item.join_status === 'SAME_GROUP'
                  ? true
                  : false,"disabled":item.join_status === 'DIFFERENT_GROUP',"indeterminate":item.join_status == 'DIFFERENT_GROUP'},on:{"input":function($event){return _vm.updateJoinStatus(item)}}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"gradient-bg white--text",attrs:{"loading":_vm.adminLoading},on:{"click":function($event){return _vm.validate_add_admin()}}},[_vm._v("save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }