<template>
  <div>
    <v-dialog v-model="editinggroup" persistent width="550px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Edit Group</v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-5">
          <v-form ref="form">
            <v-text-field
              outlined
              dense
              label="Group Name"
              v-model="AdminGroup"
              style="max-width: 470px"
              :rules="[(v) => !!v || 'Required']"
              class="ml-4"
            />
            <v-textarea
              v-model="groupdescrip"
              style="max-width: 470px"
              class="ml-4"
              outlined
              :rules="[
                (v) => v.trim().length != 0 || 'Required',
                (v) =>
                  v.length <= 100 ||
                  'Description  must be under 100 characters',
              ]"
              label="Description"
            ></v-textarea>

            <v-autocomplete
              label="Select Group Admin"
              v-model="member_name"
              :items="memberItems"
              dense
              :rules="member_rules"
              outlined
              item-text="user_email_id"
              item-value="user_id"
              hint="Member must have an e-mail to add him/her as an admin"
              :persistent-hint="true"
              style="max-width: 470px"
              class="ml-4"
            ></v-autocomplete>

            <v-card-actions class="justify-center">
              <v-btn
                :loading="adminLoading"
                @click="validate_add_admin()"
                class="gradient-bg white--text"
              >
                <!-- <v-icon class="mr-2">mdi-plus</v-icon> -->
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import { API, graphqlOperation } from "aws-amplify";
import { create_delete_groups } from "@/graphql/mutations.js";
import { ListAllUsers } from "@/graphql/queries.js";

export default {
  props: {
    editinggroup: Boolean,
    groupinfo: Object,
  },
  data: () => ({
    member_name: "",
    groupdescrip: "",
    AdminGroup: "",
    memberItems: [],
    email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    adminLoading: false,
    SnackBarComponent: {},
    member_id: "",

    email: "",
    member_rules: [(x) => x.trim() != "" || "Select Member to continue"],
  }),
  watch: {
    editinggroup: {
      async handler() {
        if (this.editinggroup == true) {
          this.get_members_by_status();
          this.AdminGroup = this.groupinfo.group_name;
          this.groupdescrip = this.groupinfo.group_description;
          this.member_name = this.groupinfo.group_admin;
          // console.log(this.groupinfo);
        }
      },
      immediate: true,
    },
  },

  methods: {
    validate_add_admin() {
      if (this.$refs.form.validate()) {
        this.delete_freeforms();
      }
    },
    async get_members_by_status() {
      try {
        this.MembersItems = [];
        let result = await API.graphql(
          graphqlOperation(ListAllUsers, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        // console.log(result.data.ListAllUsers);
        var array = result.data.ListAllUsers.Admins;

        // (this.memberItems = ),
        array.forEach((element) => {
          this.memberItems.push({
            user_email_id: element.user_email_id,
            user_id: element.user_id,
          });
        });
      } catch (err) {
        console.log("error", err);
        this.$emit("errorMsg", err.errors[0].message);
      }
    },
    async delete_freeforms() {
      this.adminLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(create_delete_groups, {
            input: {
              user_id: this.$store.getters.get_org_user_details[0].user_id,
              action_type: "EDIT",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              group_name: this.AdminGroup,
              group_description: this.groupdescrip,
              group_admin: this.member_name,
              group_id:this.groupinfo.group_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.create_delete_groups);
        this.adminLoading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.adminLoading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.adminLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.adminLoading = false;
      }
    },
    close_dialog() {
      this.member_name = "";
      this.$refs.form.resetValidation();
      this.$emit("clicked", 0);
    },
  },
};
</script>
    
    <style>
</style>