<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <v-spacer />
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-btn
              @click="open_admin_dialog()"
              small
              v-on="on"
              class="gradient-bg white--text"
              > <v-icon class="mr-2">mdi-plus</v-icon> Add Group
            </v-btn>
          </template>
          <span class="white--text">Add Group Administrator</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow"
          dense
          :headers="tabledata"
          :items="exporttable"
          :items-per-page="10"
          :fixed-header="fixed"
          :loading="isLoadingesa"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.group_description`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-if="item.group_description" class="caption" v-on="on">
                  {{
                    item.group_description.length >= 30
                      ? item.group_description.substr(0, 30) + "..."
                      : item.group_description
                  }}
                </div>
                <div v-else>-</div>
              </template>
              <div class="white--text" style="max-width: 250px">
                {{ item.group_description }}
              </div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.group_created_on`]="{ item }">
            <span v-text="get_date(item.group_created_on)"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="primary"
                  class="mr-2"
                  @click="group_memnberadd(item)"
                  v-on="on"
                  >mdi-account-multiple-plus-outline
                </v-icon>
              </template>
              <span class="white--text">Add Members</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="primary"
                  @click="group_edit(item)"
                  v-on="on"
                  >mdi-pencil
                </v-icon>
              </template>
              <span class="white--text">Edit </span>
            </v-tooltip>

            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="primary"
                  class="ml-2"
                  @click="group_delete(item)"
                  v-on="on"
                  >mdi-delete
                </v-icon>
              </template>
              <span class="white--text">Delete </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentCheck == 1">
      <GroupAdmins
        :addAdminDialog="addAdminDialog"
        @clicked="addAdminDialog = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
    <div v-if="componentCheck == 2">
      <DeleteGroup
        :groupdeletion="groupdeletion"
        @clicked="groupdeletion = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        :groupinfo="groupinfo"
      />
    </div>
    <div v-if="componentCheck == 3">
      <EditGroup
        :editinggroup="editinggroup"
        @clicked="editinggroup = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        :groupinfo="groupinfo"
      />
    </div>
    <div v-if="componentCheck == 4">
      <AddGroupmembers
        :membersgroup="membersgroup"
        @clicked="membersgroup = false"
        :groupinfo="groupinfo"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import EditGroup from "@/components/Dialogs/EditGroup.vue";
import DeleteGroup from "@/components/Dialogs/DeleteGroup.vue";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import GroupAdmins from "@/components/Dialogs/GroupAdmins.vue";
import AddGroupmembers from "@/components/Dialogs/AddGroupmembers.vue";
import { list_groups } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    GroupAdmins,
    SnackBar,
    EditGroup,
    Overlay,
    DeleteGroup,
    AddGroupmembers,
  },
  data: () => ({
    fixed: true,
    isLoadingesa: false,
    groupdeletion: false,
    membersgroup: false,
    timeZone:"",
    height: 0,
    tabledata: [
      { text: "Group Name", fixed: true, value: "group_name" },
      {
        text: "Group Description",
        fixed: true,
        value: "group_description",
        sortable: false,
      },
      {
        text: "Group Admin",
        fixed: true,
        value: "user_name",
        sortable: false,
      },
      {
        text: "Created On",
        fixed: true,
        value: "group_created_on",
        sortable: false,
      },
      {
        text: "Actions",
        fixed: true,
        value: "actions",
        sortable: false,
      },
    ],
    exporttable: [],
    addAdminDialog: false,
    componentCheck: 0,
    SnackBarComponent: {},
    editinggroup: false,
    groupinfo: {},
    overlay: false,
  }),
  created() {
    this.height = window.innerHeight - 205;
    
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.grouplist();
  },
  methods: {
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    group_edit(item) {
      this.groupinfo = item;
      this.componentCheck = 3;
      this.editinggroup = true;
    },
    group_delete(item) {
      this.componentCheck = 2;
      this.groupinfo = item;
      this.groupdeletion = true;
    },
    group_memnberadd(item) {
      this.componentCheck = 4;
      this.groupinfo = item;
      this.membersgroup = true;
    },
    open_admin_dialog() {
      this.componentCheck = 1;
      this.addAdminDialog = true;
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },

    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.addAdminDialog = false;
      this.groupdeletion = false;
      this.editinggroup = false;
      this.membersgroup = false;
      this.grouplist();
    },
    async grouplist() {
      this.overlay = true;
      this.isLoadingesa = true;
      try {
        await API.graphql(
          graphqlOperation(list_groups, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((res) => {
          // console.log(JSON.parse(res.data.list_groups));
          var response = JSON.parse(res.data.list_groups);
          if (response.Status == "SUCCESS") {
            this.exporttable = response.data;
          } else {
            this.exporttable = [];
          }
        });
        this.isLoadingesa = false;
        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.overlay = false;
        this.isLoadingesa = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>