<template>
  <div>
    <v-dialog v-model="membersgroup" persistent width="550px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text"
          >Add Members to Group : ( {{ groupinfo.group_name }} )</v-toolbar-title
        >
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text>
          <v-toolbar flat class="mt-2"
            ><v-text-field
              flat
              label="Search"
              style="max-width: 200px"
              v-model="search"
              dense
              append-icon="mdi-magnify"
              class="mt-2"
              placeholder="Search by Member Name"
            />
            <v-radio-group v-model="sorttabel" row @change="sortfunction()">
              <v-radio label="ALL" value="All"></v-radio>
              <v-radio label="Same Group" value="current_group"></v-radio>
            </v-radio-group>
          </v-toolbar>
          <v-data-table
            :height="height"
            class="overflow-y-auto overflow"
            dense
            :headers="MembersDataActive"
            :items="newMemberitems"
            :items-per-page="25"
            :fixed-header="fixed"
            :loading="isLoadingMember"
            loading-text="Loading... Please wait"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 15, 20, 25],
            }"
            :sort-by="['user_name']"
          >
            <template v-slot:[`item.join_status`]="{ item }">
              <v-simple-checkbox
                :value="
                  item.join_status === 'NO_GROUP'
                    ? false
                    : item.join_status === 'SAME_GROUP'
                    ? true
                    : false
                "
                :disabled="item.join_status === 'DIFFERENT_GROUP'"
                :indeterminate="item.join_status == 'DIFFERENT_GROUP'"
                @input="updateJoinStatus(item)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-center"
          ><v-btn
            :loading="adminLoading"
            @click="validate_add_admin()"
            class="gradient-bg white--text"
            >save</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { list_all_users_groups } from "@/graphql/queries.js";
import { add_or_remove_user_group } from "@/graphql/mutations.js";
export default {
  props: {
    membersgroup: Boolean,
    groupinfo: Object,
  },
  data() {
    return {
      MembersDataActive: [
        { text: "Member Name", fixed: true, value: "user_name", sortable: true },
        {
          text: "Group Name",
          fixed: true,
          value: "group_name",
          sortable: true,
        },
        {
          text: "Status",
          fixed: true,
          value: "join_status",
          sortable: false,
        },
      ],
      MembersItems: [],
      sortabelitems: ["All", "Current Group"],
      isLoadingMember: false,
      height: 0,
      fixed: true,
      search: "",
      adminLoading: false,
      newmemberslist: [],
      checkbit: false,
      sorttabel: "All",
      newMemberitems: [],
    };
  },
  watch: {
    membersgroup: {
      async handler() {
        {
          this.newmemberslist = [];
          this.height = window.innerHeight - 390;
          await this.list_all_users_groups();
          //   console.log(this.groupinfo);
        }
      },
      immediate: true,
    },
  },
  // computed: {
  //   filteredItems() {
  //     // Handle other cases if needed
  //     let filteredItems = [];

  //     if (this.sorttabel === "all") {
  //       filteredItems = this.MembersItems; // Show all items
  //     } else if (his.sorttabel === "Current Group") {
  //       filteredItems = this.MembersItems.filter(
  //         (item) => item.join_status === "SAME_GROUP"
  //       );
  //     }
  //     console.log(filteredItems);
  //     // Handle other cases if needed

  //     return filteredItems;
  //   },
  // },
  methods: {
    sortfunction() {
      // console.log(this.sorttabel);
      if (this.sorttabel === "All") {
        this.newMemberitems = this.MembersItems; // Show all items
      } else if (this.sorttabel === "current_group") {
        // console.log(this.MembersItems);
        this.newMemberitems = this.MembersItems.filter(
          (item) => item.join_status === "SAME_GROUP"
        );
      }
      // console.log(this.newMemberitems);
    },
    updateJoinStatus(item) {
      if (item.join_status === "NO_GROUP") {
        // this.newmemberslist.push()

        let newArray = this.removeObjectByUserId(
          this.newmemberslist,
          item.user_id
        );
        if (this.checkbit == true) {
          item.join_status = "SAME_GROUP";
          var array = {
            user_id: item.user_id,
            is_add: true,
          };
          newArray.push(array);
          this.newmemberslist = newArray;
        } else {
          item.join_status = "SAME_GROUP";
          this.newmemberslist = newArray;
        }
        // console.log(this.newmemberslist, "NO_GROUP");
        // console.log(this.newmemberslist, "newarray");
      } else if (item.join_status == "SAME_GROUP") {
        var newArray = this.removeObjectByUserId(
          this.newmemberslist,
          item.user_id
        );
        if (this.checkbit == true) {
          item.join_status = "NO_GROUP";
          var array2 = {
            user_id: item.user_id,
            is_add: false,
          };
          newArray.push(array2);
          this.newmemberslist = newArray;
        } else {
          if (item.join_status == "SAME_GROUP") {
            item.join_status = "NO_GROUP";
            var array3 = {
              user_id: item.user_id,
              is_add: false,
            };
            newArray.push(array3);
            this.newmemberslist = newArray;
          } else {
            item.join_status = "NO_GROUP";
            this.newmemberslist = newArray;
          }
        }
      }
      // console.log(this.newmemberslist, "ssss");
    },
    removeObjectByUserId(array, userIdToRemove) {
      // Use the filter method to create a new array without the object with the specified user_id
      const userExists = array.some((obj) => obj.user_id === userIdToRemove);

      if (userExists) {
        // If user_id exists, remove the object with the specified user_id
        const newArray = array.filter((obj) => obj.user_id !== userIdToRemove);
        // console.log(
        //   `User with user_id ${userIdToRemove} removed. Updated array:`
        // );
        // console.log(newArray);
        this.checkbit = false;

        return newArray;
      } else {
        // If user_id doesn't exist, perform some other action or log a message
        // console.log(
        //   `User with user_id ${userIdToRemove} does not exist in the array.`
        // );
        this.checkbit = true;
        return array; // Return the original array unchanged
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
    async list_all_users_groups() {
      try {
        let result = await API.graphql(
          graphqlOperation(list_all_users_groups, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              group_id: this.groupinfo.group_id,
            },
          })
        );
        this.MembersItems = JSON.parse(result.data.list_all_users_groups).data;
        // console.log(this.MembersItems);
        this.newMemberitems = this.MembersItems;
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
      }
    },
    async validate_add_admin() {
      this.adminLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(add_or_remove_user_group, {
            input: {
              user_ids: this.newmemberslist,
              group_id: this.groupinfo.group_id,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.add_or_remove_user_group);
        this.adminLoading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.adminLoading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.adminLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.adminLoading = false;
      }
    },
  },
};
</script>

<style>
</style>