var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"gradient-bg white--text",attrs:{"small":""},on:{"click":function($event){return _vm.open_admin_dialog()}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add Group ")],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Add Group Administrator")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"height":_vm.height,"dense":"","headers":_vm.tabledata,"items":_vm.exporttable,"items-per-page":10,"fixed-header":_vm.fixed,"loading":_vm.isLoadingesa,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"item.group_description",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item.group_description)?_c('div',_vm._g({staticClass:"caption"},on),[_vm._v(" "+_vm._s(item.group_description.length >= 30 ? item.group_description.substr(0, 30) + "..." : item.group_description)+" ")]):_c('div',[_vm._v("-")])]}}],null,true)},[_c('div',{staticClass:"white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(item.group_description)+" ")])])]}},{key:"item.group_created_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.group_created_on))}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.group_memnberadd(item)}}},on),[_vm._v("mdi-account-multiple-plus-outline ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Add Members")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.group_edit(item)}}},on),[_vm._v("mdi-pencil ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Edit ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.group_delete(item)}}},on),[_vm._v("mdi-delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Delete ")])])]}}],null,true)})],1)],1),(_vm.componentCheck == 1)?_c('div',[_c('GroupAdmins',{attrs:{"addAdminDialog":_vm.addAdminDialog},on:{"clicked":function($event){_vm.addAdminDialog = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 2)?_c('div',[_c('DeleteGroup',{attrs:{"groupdeletion":_vm.groupdeletion,"groupinfo":_vm.groupinfo},on:{"clicked":function($event){_vm.groupdeletion = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 3)?_c('div',[_c('EditGroup',{attrs:{"editinggroup":_vm.editinggroup,"groupinfo":_vm.groupinfo},on:{"clicked":function($event){_vm.editinggroup = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 4)?_c('div',[_c('AddGroupmembers',{attrs:{"membersgroup":_vm.membersgroup,"groupinfo":_vm.groupinfo},on:{"clicked":function($event){_vm.membersgroup = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }